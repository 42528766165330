import React from "react";
import { Helmet } from "react-helmet";

function Contact() {
  const [loadCounter, setLoadCounter] = React.useState(0);

  const handleLoad = () => {
    setLoadCounter((prev) => {
      if (prev + 1 === 2) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }
      return prev + 1;
    });
  };

  return (
    <>
      <Helmet>
        <title>İletişim | davetlim.org</title>
        <meta
          name="description"
          content="davetlim.org ile iletişime geçin. Sorularınızı, önerilerinizi veya taleplerinizi bize iletin. Ekibimiz size en kısa sürede dönüş yapacaktır."
        />
        <meta name="author" content="davetlim.org" />
        <meta name="robots" content="index, follow" />
        {/* Facebook Tags  */}
        <meta name="og:title" content="İletişim | davetlim.org" />
        <meta
          name="og:description"
          content="davetlim.org ile iletişime geçin. Sorularınızı, önerilerinizi veya taleplerinizi bize iletin. Ekibimiz size en kısa sürede dönüş yapacaktır."
        />
        <meta name="og:url" content="https://davetlim.org/iletisim#top" />
        <meta name="og:site_name" content="davetlim.org" />
        <meta name="og:type" content="website" />
        <meta
          name="og:image"
          content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png"
        />
        {/* Twitter tags  */}
        <meta name="twitter:title" content="İletişim | davetlim.org" />
        <meta
          name="twitter:description"
          content="davetlim.org ile iletişime geçin. Sorularınızı, önerilerinizi veya taleplerinizi bize iletin. Ekibimiz size en kısa sürede dönüş yapacaktır."
        />
        <meta
          name="twitter:image"
          content="https://davetlim.org/static/media/Logo.1668ecc39d9ff250c6c6.png"
        />
        <meta name="twitter:image:alt" content="davetlim.org" />
        <meta name="twitter:site" content="https://davetlim.org/iletisim#top" />
        <meta name="twitter:creator" content="davetlim.org" />
      </Helmet>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="grid grid-cols-1">
            <div className="col-span-2 mb-8 lg:mb-0 flex justify-center">
              <iframe
                onLoad={handleLoad}
                title="Contact Form"
                src="https://docs.google.com/forms/d/e/1FAIpQLScVT45Xym8O15kvdskRNM7qqfUivc4J14vd1j6XZh_2CdQ0lw/viewform?embedded=true"
                width="640"
                height="2000"
              >
                Loading…
              </iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
